import React from 'react';

import ChangePasswordForm from './ChangePasswordForm';
import './changePassword.scss';

const ChangePassword = (props) => (
  <div>
    <div className="login-page--header">
      <h1>Entrez dans la bulle Calicéo</h1>
    </div>
    <ChangePasswordForm location={props.location} />
  </div>
);

export default ChangePassword;
