import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import qs from 'qs';
import queryString from 'query-string';
import { Form, Input, notification } from 'antd';

import { APIWOI } from 'api';
import { centerCookieIds } from 'utils/constants';

const ChangePasswordForm = (props) => {
  const { selectedCenter, actions, location } = props;
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [userToken, setUserToken] = useState('');

  useEffect(() => {
    const token = queryString.parse(location.search);
    setUserToken(token?.tk || '');
  }, [location]);

  const onSubmit = (values) => {
    const resetPassValues = {
      password: values.password,
      token: userToken,
    };

    APIWOI.post('/frbttxpsediboh', qs.stringify(resetPassValues)).then((res) => {
      if (res.data.status === 1) {
        setErrorMessage('');
        notification.success({
          message: 'Changement de mot de passe réussi',
        });
        navigate('/');
      } else {
        setErrorMessage(res.data.responseData.errorMessage);
        notification.error({
          message: 'Une erreur s\'est produite. Veuillez réessayer',
        });
      }
    }).catch((e) => {
      notification.error({
        message: 'Une erreur s\'est produite. Veuillez réessayer',
      });
    });
  };

  return (
    <div className="login-form--section">
      <div className="login-form--wrapper">
        <div className="login-form">
          <h3 className="login-form--title">Définir votre nouveau mot de passe</h3>
          <Form layout="vertical" form={form} name="control-hooks" onFinish={onSubmit}>
            <Form.Item
              name="password"
              label="Mot de passe (Le mot de passe doit contenir 10 caractères, une majuscule et un caractère spécial minimum)"
              validateTrigger="onSubmit"
              rules={[{ required: true, message: 'Le champ mot de passe n\'est pas saisi' },
                      {
                          pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/,
                          message: 'Le mot de passe doit contenir 10 caractères, un chiffre, une majuscule et un caractère minimum',
                        }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirmation"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Veuillez confirmer votre mot de passe',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Les deux mots de passe que vous avez saisis ne correspondent pas');
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <div className="change-pass-buttons--wrapper">
                <button type="submit" className="primary-caliceo--button">
                  Modifier
                </button>
                <button type="button" onClick={() => navigate('/')} className="secondary-caliceo--button">
                  Retour au site
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
