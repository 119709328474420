import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import ChangePassword from 'components/change-password/ChangePassword';
import Reinsurance from 'components/home-group/reinsurance/Reinsurance';

const ChangePasswordPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Changer le mot de passe" />
    <ChangePassword />
    <Reinsurance location={props.location} />
  </Layout>
);

export default ChangePasswordPage;
